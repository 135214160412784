<script>
/* COMPOSANTS */
import MultiSelect from 'primevue/multiselect';
import AkAutocomplete from '@components/v2/input/AkAutocomplete.vue';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import prestationTypesConst from '@mixins/const/prestationTypesConst';
import prestationStatusFilterConst from '@mixins/const/prestationStatusConst';
import personnelTypeConst from '@mixins/const/personnelTypeConst';
import companyMixin from '@mixins/companyMixin';
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import reservationAutocompleteMixin from '@mixins/autocomplete/reservationAutocompleteMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';

/* SERVICES */

export default {
  components: {
    AkAutocomplete,
    MultiSelect,
    AkButtonFooterSidebarFilter,
  },
  mixins: [
    utilsMixin,
    sidebarMixin,
    companyMixin,
    permissionsMixin,
    prestationTypesConst,
    ownerAutocompleteMixin,
    accommodationAutocompleteMixin,
    reservationAutocompleteMixin,
    prestationStatusFilterConst,
    prestationTypesConst,
    personnelTypeConst,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      statuses: [],
    };
  },
  mounted() {},
  methods: {
    itemSelect(item) {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      if (this.$refs.reservationAutocomplete) this.$refs.reservationAutocomplete.reset();
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    prestationTypesList() {
      return this.prestationTypesConst.slice(0, -1);
    },
    prestationStatusList() {
      if (this.isHousekeeper()) return this.prestationStatusForPlanningIntervenant;
      return this.prestationStatusFilterConst;
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <!-- Agences (si GAS) -->
    <MultiSelect
      v-if="this.isGas() && this.companies"
      v-model="currentValue.companyIds"
      :placeholder="$t('company.one')"
      :options="companies"
      :loading="loadingCompanies"
      option-label="name"
      option-value="id"
      @change="itemSelect"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      inline />

    <AkAutocomplete
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      :inline="true"
      :label="$t('owner.ownerDisplay')"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      :force-selection="false"
      :init-method="this.autocompleteOwnerById"
      :search-method="this.autocompleteOwner"
      :search-object="this.autocompleteOwnerCustomer()"
      :search-label="this.autocompleteOwnerQuery" />

    <!-- Logement -->
    <AkAutocomplete
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      :inline="true"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      :placeholder="$t('accommodation.short')"
      :search-method="this.autocompleteAccommodation"
      :search-label="this.autocompleteAccommodationQuery"
      :init-method="this.autocompleteAccommodationById" />

    <AkAutocomplete
      ref="reservationAutocomplete"
      v-model="currentValue.reservationId"
      :inline="true"
      @item-select="itemSelect"
      :placeholder="$t('reservation.one')"
      option-value="id"
      option-label="display"
      :init-method="this.autocompleteReservationById"
      :search-method="this.autocompleteReservation" />

    <!-- Type de personnel affecté -->
    <MultiSelect
      v-if="this.isGasGav()"
      v-model="currentValue.assignedStaffs"
      :placeholder="$t('assigned_staff.type')"
      :options="personnelTypeConst"
      option-label="label"
      option-value="value"
      @change="itemSelect"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      inline />

    <!-- Types de mission -->
    <MultiSelect
      v-model="currentValue.types"
      :placeholder="$t('mission_order.type')"
      :options="prestationTypesList"
      option-label="label"
      option-value="value"
      @change="itemSelect"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      inline />

    <!-- Statut des missions -->
    <MultiSelect
      v-model="currentValue.statusList"
      :placeholder="$t('planning_intervenant.status')"
      :options="prestationStatusList"
      option-label="label"
      option-value="value"
      @change="itemSelect"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      inline />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search" @reset="reset" />
  </form>
</template>
