<script>
import moment from 'moment/moment';
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */
import GaView from '@components/v2/layout/GaView.vue';
import GaListView from '@components/v2/layout/GaListView.vue';
import AkAllFilter from '@components/v2/filter/AkAllFilter.vue';
import AkListFilter from '@components/v2/filter/AkListFilter.vue';
import AkAutocompleteFilter from '@components/v2/filter/AkAutocompleteFilter.vue';
import PendingMissionList from '@views/operationnel/mission/components/PendingMissionList';
import ParticipantPlanningList from '@views/operationnel/mission/components/ParticipantPlanningList';
import ParticipantPlanningDetails from '@views/operationnel/mission/components/ParticipantPlanningDetails';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import loaderMixin from '@/mixins/loaderMixin';
import companyMixin from '@mixins/companyMixin';
import missionMixin from '@mixins/missionMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import accommodationAutocompleteMixin from '@/mixins/autocomplete/accommodationAutocompleteMixin';

/* SERVICES */
import missionPlanningParticipantService from '@/services/missionPlanningParticipantService';

export default {
  components: {
    PendingMissionList,
    AkAutocompleteFilter,
    AkListFilter,
    AkAllFilter,
    GaListView,
    GaView,
    ParticipantPlanningList,
    ParticipantPlanningDetails,
  },
  mixins: [
    permissionsMixin,
    utilsMixin,
    loaderMixin,
    messageMixin,
    sidebarMixin,
    missionMixin,
    ownerAutocompleteMixin,
    accommodationAutocompleteMixin,
    companyMixin,
  ],
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      loading: false,
      planningList: [],
      pendingList: [],
      dates: undefined,
      textSearch: null,
      filter: {},
    };
  },
  computed: {
    hasOneParticipant() {
      return this.planningList.length === 1;
    },
    periodStartDisplay() {
      if (this.dates) return moment(this.dates[0]).format('ddd D MMM');
      return '';
    },
    periodEndDisplay() {
      if (this.dates) return moment(this.dates[this.dates.length - 1]).format('ddd D MMM');
      return '';
    },
  },
  mounted() {
    this.getCurrentWeek();
  },
  watch: {},
  methods: {
    // FILTER
    openFilterPanel() {
      this.toggleFilterTitle(
        'GaMissionPlanningParticipantFilter',
        this.$t('filter_all'),
        this.filter,
        this.search,
        this.resetFilter,
      );
    },
    resetFilter() {
      this.filter = {};
      let dates = this.getDatesStrOfTheWeek(moment().weekday(0));
      this.filter.dateStart = this.dateToUtc(dates[0]);
      this.filter.dateEnd = this.dateToUtc(dates[6]);
      this.toggleFilterTitle(
        'GaMissionPlanningParticipantFilter',
        this.$t('filter_all'),
        this.filter,
        this.search,
        this.resetFilter,
      );
    },

    // REFRESH DATA
    search() {
      // Reset les listes pour forcer le rafraîchissement des Datatable
      this.$nextTick(() => {
        this.showLoader(this.$refs.planning);
      });

      let promiseList = [];

      if (this.isGasGav()) {
        // Rechercher les missions à planifier pour les salariés et concierges
        let p1 = missionPlanningParticipantService.pending(this.filter);

        p1.then((data) => {
          this.pendingList = data;
        }).catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        });

        promiseList.push(p1);

        // Rechercher les missions affectées aux salariés et/ou prestataires
        let p2 = missionPlanningParticipantService.planning(this.filter);

        p2.then((data) => {
          this.planningList = data;
          this.dates = this.getDatesStrOfTheWeek(this.filter.dateStart);
        }).catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        });

        promiseList.push(p2);
      } else if (this.isHousekeeper()) {
        // Rechercher les missions du concierge actuellement connecté
        const filter = {...this.filter, assignedStaff: 'HOUSEKEEPER'};
        let pHousekeeper = missionPlanningParticipantService.participant(this.myHousekeeperId(), filter);

        pHousekeeper
          .then((data) => {
            this.planningList = [data];
            this.dates = this.getDatesStrOfTheWeek(this.filter.dateStart);
          })
          .catch((e) => {
            let message = this.msgForErr(this.$t, e);
            this.addError(message);
          });

        promiseList.push(pHousekeeper);
      }

      Promise.all(promiseList).then(() => {
        this.$nextTick(() => {
          this.hideLoader();
        });
      });
    },

    // Actions sur dates et recherche

    getCurrentWeek() {
      let dates = this.getDatesStrOfTheWeek(moment().weekday(-2).startOf('day'));
      this.filter.dateStart = this.dateToUtc(dates[0]);
      this.filter.dateEnd = this.dateToUtc(dates[6]);
      this.search();
    },

    onPreviousWeekClick() {
      let dates = this.getDatesStrOfTheWeek(this.addDays(moment(this.dates[0]).startOf('day'), -7));
      this.filter.dateStart = this.dateToUtc(dates[0]);
      this.filter.dateEnd = this.dateToUtc(dates[6]);
      this.search();
    },
    onNextWeekClick() {
      let dates = this.getDatesStrOfTheWeek(this.addDays(moment(this.dates[0]).startOf('day'), 7));
      this.filter.dateStart = this.dateToUtc(dates[0]);
      this.filter.dateEnd = this.dateToUtc(dates[6]);
      this.search();
    },
    submitQuery(query) {
      this.filter.query = query;
      this.search();
    },
  },
};
</script>

<template>
  <GaView>
    <template #action v-if="!this.$isMobile()">
      <span @click="getCurrentWeek()" class="btn-primary-gradient xs cursor-pointer"> Aujourd'hui </span>
    </template>
    <!-- Contenu de la page -->
    <template #content>
      <div class="flex justify-start items-center gap-2 mb-2" v-if="this.dates">
        <i
          class="ga-icon ga-chevron_left text-4xl text-gray cursor-pointer hover:text-dark"
          @click="onPreviousWeekClick"></i>
        <span class="font-normal text-xl text-gray"
          >Du <span class="capitalize text-dark font-medium">{{ periodStartDisplay }}</span> au
          <span class="capitalize text-dark font-medium">{{ periodEndDisplay }}</span></span
        >
        <i
          class="ga-icon ga-chevron_right text-4xl text-gray cursor-pointer hover:text-dark"
          @click="onNextWeekClick"></i>
      </div>
      <!--MISSION PENDING-->
      <PendingMissionList v-model="this.pendingList" @mission-assigned="search()" />
      <GaListView @search="submitQuery" :searchable="this.isGasGav()">
        <template #filter>
          <AkListFilter
            v-if="this.isGas()"
            :multi-selection="true"
            v-model="this.filter.companyIds"
            :items="this.companies"
            :display-filter="true"
            @search="this.search"
            :label="$t('employee.company')"
            direction="right" />
          <AkAutocompleteFilter
            v-model="this.filter.ownerId"
            @search="this.search"
            label="Hôte"
            item-value="id"
            item-display="display"
            direction="right"
            :init-method="this.autocompleteOwnerById"
            :search-method="this.autocompleteOwner"
            :search-object="this.autocompleteOwnerCustomer()"
            :search-label="this.autocompleteOwnerQuery" />
          <AkAutocompleteFilter
            v-model="filter.accommodationId"
            :init-method="autocompleteAccommodationById"
            :label="$t('accommodation_title')"
            :search-label="autocompleteAccommodationQuery"
            :search-method="autocompleteAccommodation"
            direction="right"
            item-display="display"
            item-value="id"
            @search="search" />
          <AkAllFilter @open="openFilterPanel" />
        </template>
        <template #content>
          <div class="planning" ref="planning">
            <!-- PLANNING DES INTERVENANTS -->

            <!-- Vision individuelle -->
            <!-- TODO: enlever le this.isHousekeeper() pour débloquer la vue unique -->
            <ParticipantPlanningDetails
              v-if="this.hasOneParticipant && this.isHousekeeper()"
              v-model="this.planningList[0]"
              :dates="this.dates" />

            <!-- Vision globale-->

            <ParticipantPlanningList
              v-else
              :hasPending="this.pendingList.length > 0"
              v-model="this.planningList"
              :dates="this.dates" />
          </div>
        </template>
      </GaListView>
    </template>
  </GaView>
</template>
