<script>
import {getDayShort, getMonthShort} from '@utils/dateTime';
import moment from 'moment';

/* COMPOSANTS */
import AkAccommodationPrestationLine from '@components/general/AkAccommodationPrestationLine.vue';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import missionMixin from '@mixins/missionMixin';
import prestationTypesConst from '@mixins/const/prestationTypesConst';
import personnelTypeConst from '@mixins/const/personnelTypeConst';
import prestationStatusConst from '@mixins/const/prestationStatusConst';

/* SERVICES */

export default {
  components: {AkAccommodationPrestationLine},
  mixins: [utilsMixin, missionMixin, prestationTypesConst, prestationStatusConst, personnelTypeConst],
  props: {
    modelValue: {
      required: true,
    },
    dates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {height: ''};
  },
  setup() {
    return {};
  },
  watch: {},
  mounted() {
    let p = undefined;
    if (this.$isMobile()) {
      p = 470;
    } else {
      p = 375;
    }
    this.height = 'max-height: calc(100vh - ' + p + 'px)';
  },
  updated() {},
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    slots() {
      let list = [];
      if (this.dates)
        for (let d of this.dates) {
          let date = moment(d).startOf('date');
          let slot = {};
          slot.date = date;
          slot.firstDayOfMonth = date.date() === 1;
          slot.firstDay = d === this.dates[0];
          slot.dayNumber = date.format('DD');
          slot.dayNumberTitle = date.format('DD/MM');
          slot.dayTitle = date.format('ddd');
          slot.wk = date.isoWeekday() === 7 || date.isoWeekday() === 6;
          slot.today = date.isSame(moment().startOf('date'));
          list.push(slot);
        }
      return list;
    },
  },
  methods: {
    getDayShort,
    getMonthShort,
    displayTooltip() {},
  },
};
</script>

<template>
  <perfect-scrollbar class="ak-planning">
    <div class="flex border-b border-dashed border-lightGray min-w-[1800px] lg:min-w-[auto]">
      <div class="w-1/5 border-0 border-lightGray border-y-0">
        <div
          class="flex gap-4 items-center py-2 px-3.5 h-full text-sm font-semibold opacity-80 border-0 border-r border-lightGray uppercase text-gray">
          <div class="flex gap-4 items-center py-2 font-semibold uppercase text-gray">Logement</div>
        </div>
      </div>
      <div class="w-4/5 relative">
        <div class="grid w-full h-full items-center z-10 grid-cols-7">
          <div
            v-for="n in slots"
            class="text-center text-gray opacity-80 h-full flex flex-col items-center justify-center text-sm font-semibold gap-2 px-2 py-4"
            :key="n">
            <span class="uppercase font-semibold">{{ n.dayTitle }}</span>
            <span class="font-semibold">{{ n.dayNumberTitle }}</span>
          </div>
        </div>
      </div>
    </div>
    <perfect-scrollbar
      class="min-w-[1800px] lg:min-w-[auto] ps_rail_index border-l border-r border-lightGray"
      :options="{suppressScrollX: true}">
      <div v-if="currentValue && currentValue.length > 0" class="body" :style="this.height">
        <AkAccommodationPrestationLine
          v-for="(p, idx) in currentValue"
          :key="p"
          v-model="currentValue[idx]"
          :slots="slots"
          @tooltip="displayTooltip" />
      </div>
      <div v-else class="flex flex-col gap-2 mt-8 mb-8 items-center justify-center">
        <div>
          <span class="ga-icon ga-calendar text-4xl" />
        </div>
        <div>Aucune mission</div>
      </div>
    </perfect-scrollbar>
    <div
      class="flex justify-between items-center gap-4 border px-2 py-2 border-lightGray min-w-[1800px] lg:min-w-[auto]">
      <div class="flex gap-4">
        <div v-if="this.isGasGav()" class="flex gap-2 items-center">
          <i class="w-[12px] h-[12px] rounded-full bg-[#F9895E]"></i
          ><span class="text-dark text-base font-normal"> {{ this.displayForPrestationStatusConst('PROPOSED') }}</span>
        </div>
        <div v-if="this.isGasGav()" class="flex gap-2 items-center">
          <i class="w-[12px] h-[12px] rounded-full bg-[#F27438]"></i
          ><span class="text-dark text-base font-normal">{{ this.displayForPrestationStatusConst('TO_PLAN') }}</span>
        </div>
        <div class="flex gap-2 items-center">
          <i class="w-[12px] h-[12px] rounded-full bg-[#75BB99]"></i
          ><span class="text-dark text-base font-normal">{{ this.displayForPrestationStatusConst('TODO') }}</span>
        </div>
        <div class="flex gap-2 items-center">
          <i class="w-[12px] h-[12px] rounded-full bg-[#27B363]"></i
          ><span class="text-dark text-base font-normal">{{ this.displayForPrestationStatusConst('COMPLETED') }}</span>
        </div>
        <div class="flex gap-2 items-center">
          <i class="w-[12px] h-[12px] rounded-full bg-[#E3324B]"></i
          ><span class="text-dark text-base font-normal">{{ this.displayForPrestationStatusConst('CANCELED') }}</span>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</template>
