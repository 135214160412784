<script>
import useVuelidate from '@vuelidate/core';
import moment from 'moment';

/* COMPOSANTS */
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import GaMessages from '@components/ga/layout/GaMessages';
import AkButtonValidation from '@components/v2/general/AkButtonValidation';
import AkSidebar from '@components/v2/general/AkSidebar';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import missionOrderService from '@services/missionOrderService';
import AkTime from '@components/v2/input/AkTime.vue';
import AkAvatar from '@components/general/AkAvatar.vue';
import AkInputTextArea from '@components/v2/input/AkInputTextArea.vue';
import AkInputText from '@components/v2/input/AkInputText.vue';
import AkCalendar from '@components/v2/input/AkCalendar.vue';
import AkLoader from '@components/general/AkLoader.vue';
import {required} from '@vuelidate/validators';

export default {
  components: {
    AkLoader,
    AkCalendar,
    AkInputText,
    AkInputTextArea,
    AkAvatar,
    AkTime,
    AkSidebar,
    AkButtonValidation,
    GaMessages,
    AkFormSubmitted,
  },
  mixins: [messageMixin, permissionsMixin, utilsMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  emits: ['update-done'],
  props: {
    missionId: {
      required: true,
    },
  },
  data() {
    return {
      createSidebarVisible: false,
      submitted: false,
      time: '',
      mission: undefined,
    };
  },
  validations() {
    return {
      currentValue: {
        dateStart: {required},
      },
    };
  },
  mounted() {},
  watch: {
    time(newVal, oldVal) {
      // Mettre à jour les heures et minutes de la mission
      this.currentValue.dateStart.setHours(newVal.getHours());
      this.currentValue.dateStart.setMinutes(newVal.getMinutes());
      // Ajouter la durée (en minutes) à la date de début pour obtenir la date de fin
      this.currentValue.dateEnd = new Date(moment(this.currentValue.dateStart).add(this.duration, 'm'));
    },

    'currentValue.dateStart'(newVal, oldVal) {
      // Mettre à jour les heures et minutes de la mission
      if (oldVal) {
        this.currentValue.dateStart.setHours(oldVal.getHours());
        this.currentValue.dateStart.setMinutes(oldVal.getMinutes());
      }
      this.currentValue.dateEnd = new Date(moment(this.currentValue.dateStart).add(this.duration, 'm'));
    },
  },
  methods: {
    show() {
      this.createSidebarVisible = true;
      this.$nextTick(() => {
        this.init();
      });
    },
    hide() {
      this.createSidebarVisible = false;
    },
    init() {
      this.$refs.loader.load();
      missionOrderService
        .get(this.missionId)
        .then((data) => {
          this.mission = data;
          this.initDate();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.$refs.loader.hide();
        });
    },
    update() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) {
        this.$refs.submit.init();
        return;
      }
      missionOrderService
        .update(this.currentValue.id, this.currentValue)
        .then(() => {
          this.success(this.$t('mission_order_details.edit_success'));
          this.$emit('update-done');
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.createSidebarVisible = false;
        });
    },
    initDate() {
      if (!this.currentValue) return;
      // Formatage des dates pour missions des prestataires / salariés / concierges
      this.prestationParticipants?.forEach((prestationParticipant) => {
        if (prestationParticipant.clockIn) prestationParticipant.clockIn = new Date(prestationParticipant.clockIn);
      });
      // Calcul de la durée (minutes) à partir de la différence entre date de début et date de fin
      const dateStart = new Date(this.currentValue.dateStart);
      const dateEnd = new Date(this.currentValue.dateEnd);
      const diffMins = this.diffMins(dateStart, dateEnd);
      this.duration = diffMins;
      this.time = dateStart;
    },
  },
  computed: {
    currentValue() {
      return this.mission;
    },
    prestationParticipants() {
      return this.currentValue?.prestationParticipants;
    },
    // IS
    isAssignedStaffPrestataire() {
      return this.currentValue?.assignedStaff === 'PRESTATAIRE';
    },
    isAssignedStaffIntern() {
      return this.currentValue?.assignedStaff === 'INTERN';
    },
    isAssignedStaffHousekeeper() {
      return this.currentValue?.assignedStaff === 'HOUSEKEEPER';
    },
    isAssignedStaffPartner() {
      return this.currentValue?.assignedStaff === 'PARTNER';
    },

    isStatusPrestationCanceled() {
      return this.currentValue?.statusPrestation === 'CANCELED';
    },
    isTimeToPlan() {
      if (!(this.time instanceof Date)) return true;
      return (
        (this.isAssignedStaffIntern || this.isAssignedStaffHousekeeper) &&
        this.time.getHours() === 0 &&
        this.time.getMinutes() === 0
      );
    },
  },
};
</script>

<template>
  <AkSidebar
    :baseZIndex="9998"
    v-model:visible="createSidebarVisible"
    block-scroll
    position="right"
    :dismissable="true">
    <template #container>
      <div class="mb-4">
        <GaMessages ref="sidebarMessages" />
      </div>
      <div class="p-sidebar-header">
        <div class="p-sidebar-header-content">Modifier la mission</div>
        <button class="p-sidebar-close p-sidebar-icon p-link" type="button" @click="hide">
          <span
            class="p-sidebar-close-icon ga-icon ga-close_circle text-4xl text-gray hover:text-primary hover:bg-transparent" />
        </button>
      </div>
      <div class="p-sidebar-content">
        <perfect-scrollbar class="h-full flex flex-col gap-4">
          <AkLoader ref="loader" scale="1" message="Récupération de la mission" color="var(--color-primary)">
            <template #content>
              <AkFormSubmitted :submitted="submitted" class="flex flex-col gap-4" reference="form" @submit="update()">
                <AkCalendar
                  :validator="v$.currentValue.dateStart"
                  v-model="currentValue.dateStart"
                  date-format="dd/mm/yy"
                  label="Prestation" />
                <div class="input-item" v-if="!this.isAssignedStaffPartner">
                  <AkTime
                    v-if="!this.isTimeToPlan"
                    v-model="time"
                    label="Heure de début prévue"
                    class-name="col-12"
                    class="no-pad-no-gap" />
                  <div v-else class="col-12 form-group no-pad-no-gap">
                    <AkInputText
                      type="text"
                      value="A planifier"
                      disabled
                      label="Heure de début prévue"
                      model-value="A planifier" />
                  </div>
                </div>
                <!-- Missions prestataires (offres)-->

                <div v-if="isAssignedStaffPrestataire && prestationParticipants">
                  <span class="title-border">Informations des prestataires</span>
                  <div
                    class="flex flex-col gap-2 mt-4"
                    v-for="(offer, idx) in prestationParticipants"
                    v-bind:key="offer.id">
                    <div class="flex flex-col gap-2">
                      <div class="flex gap-2 items-center flex-1" v-if="offer.participantId">
                        <AkAvatar
                          size="medium"
                          :user-id="offer.userId"
                          :first-name="offer.personFirstName"
                          :last-name="offer.personLastName" />
                        <router-link
                          class="text-primary hover:text-primary text-md font-semibold"
                          :to="{name: 'prestatairePage', params: {prestataireId: offer.participantId}}">
                          {{ offer.personDisplay }}
                        </router-link>
                      </div>
                      <div v-else class="flex flex-1 flex-col gap-1">
                        <span class="font-inter font-semibold text-dark text-base">Prestataire {{ idx + 1 }}</span>
                        <span class="font-inter font-medium text-gray text-md">
                          {{ offer.personDisplay }}
                        </span>
                      </div>
                      <div class="flex gap-2">
                        <AkInputText v-model="offer.priceHt" class-name="flex-1" label="Tarif de la prestation" />
                        <AkTime
                          v-model="offer.clockIn"
                          label="Heure de début du prestataire"
                          disabled
                          class-name="flex-1" />
                      </div>
                    </div>
                    <div class="flex flex-col gap-2">
                      <AkInputTextArea
                        auto-resize
                        :rows="4"
                        v-model="offer.commentForPerson"
                        placeholder=""
                        label="Commentaire pour le prestataire"
                        class-name="flex-1" />
                      <AkInputTextArea
                        auto-resize
                        :rows="4"
                        v-model="offer.commentOnAccommodation"
                        placeholder=""
                        label="Commentaire du prestataire sur le logement"
                        disabled
                        class-name="flex-1" />
                    </div>
                  </div>
                </div>

                <!-- Missions salariés -->

                <div v-if="isAssignedStaffIntern && prestationParticipants">
                  <span class="title-border">Informations des salariés</span>
                  <div
                    class="flex flex-col gap-2 mt-4"
                    v-for="(prestationEmployee, idx) in prestationParticipants"
                    v-bind:key="prestationEmployee.id">
                    <div class="flex md:flex-row flex-col gap-2">
                      <div class="flex gap-2 items-center flex-1" v-if="prestationEmployee.participantId">
                        <AkAvatar
                          size="medium"
                          :user-id="prestationEmployee.userId"
                          :first-name="prestationEmployee.personFirstName"
                          :last-name="prestationEmployee.personLastName" />
                        <router-link
                          class="text-primary hover:text-primary text-md font-semibold"
                          :to="{name: 'employeePage', params: {employeeId: prestationEmployee.participantId}}">
                          {{ prestationEmployee.personDisplay }}
                        </router-link>
                      </div>
                      <div v-else class="flex flex-1 flex-col gap-1">
                        <span class="font-inter font-semibold text-dark text-base">Salarié {{ idx + 1 }}</span>
                        <span class="font-inter font-medium text-gray text-md">
                          {{ prestationEmployee.personDisplay }}
                        </span>
                      </div>

                      <AkTime
                        class-name="flex-1"
                        v-model="prestationEmployee.clockIn"
                        label="Heure de début du salarié" />
                    </div>
                    <div class="flex flex-col gap-2">
                      <AkInputTextArea
                        auto-resize
                        :rows="4"
                        class-name="flex-1"
                        v-model="prestationEmployee.commentForPerson"
                        label="Commentaire pour le salarié" />
                      <AkInputTextArea
                        auto-resize
                        :rows="4"
                        class-name="flex-1"
                        v-model="prestationEmployee.commentOnAccommodation"
                        label="Commentaire du salarié sur le logement"
                        disabled />
                    </div>
                  </div>
                </div>

                <!-- Missions concierges -->

                <div v-if="isAssignedStaffHousekeeper && prestationParticipants">
                  <span class="title-border">Informations du concierge</span>

                  <div
                    class="flex flex-col gap-2 mt-4"
                    v-for="(prestationHousekeeper, idx) in prestationParticipants"
                    v-bind:key="prestationHousekeeper.id">
                    <div class="flex md:flex-row flex-col gap-2">
                      <div class="flex gap-2 items-center flex-1" v-if="prestationHousekeeper.participantId">
                        <AkAvatar
                          size="medium"
                          :user-id="prestationHousekeeper.userId"
                          :first-name="prestationHousekeeper.personFirstName"
                          :last-name="prestationHousekeeper.personLastName" />
                        <router-link
                          class="text-primary hover:text-primary text-md font-semibold"
                          :to="{name: 'housekeeperPage', params: {housekeeperId: prestationHousekeeper.participantId}}">
                          {{ prestationHousekeeper.personDisplay }}
                        </router-link>
                      </div>

                      <div v-else class="flex flex-1 flex-col gap-1">
                        <span class="font-inter font-semibold text-dark text-base">Concierge {{ idx + 1 }}</span>
                        <span class="font-inter font-medium text-gray text-md">
                          {{ prestationHousekeeper.personDisplay }}
                        </span>
                      </div>
                      <AkTime
                        v-model="prestationHousekeeper.clockIn"
                        label="Heure de début du concierge"
                        class-name="flex-1"
                        disabled />
                    </div>
                    <div class="flex flex-col gap-2">
                      <AkInputTextArea
                        auto-resize
                        :rows="4"
                        v-model="prestationHousekeeper.commentForPerson"
                        class-name="flex-1"
                        label="Commentaire pour le concierge" />
                      <AkInputTextArea
                        :auto-resize="false"
                        :rows="4"
                        class-name="flex-1"
                        v-model="prestationHousekeeper.commentOnAccommodation"
                        label="Commentaire du concierge sur le logement"
                        disabled />
                    </div>
                  </div>
                </div>

                <!-- Missions partenaires (objectif : être générique à tous les profils) -->

                <div v-if="isAssignedStaffPartner && prestationParticipants">
                  <span class="title-border">Informations des partenaires</span>
                  <div
                    class="flex flex-col gap-2 mt-4"
                    v-for="(prestationParticipant, idx) in prestationParticipants"
                    v-bind:key="prestationParticipant.id">
                    <div class="flex md:flex-row flex-col gap-2">
                      <div class="flex gap-2 items-center flex-1" v-if="prestationParticipant.participantId">
                        <AkAvatar
                          size="medium"
                          :user-id="prestationParticipant.userId"
                          :first-name="prestationParticipant.personFirstName"
                          :last-name="prestationParticipant.personLastName" />
                        <router-link
                          class="text-primary hover:text-primary text-md font-semibold"
                          :to="{name: 'partenairePage', params: {partenaireId: prestationParticipant.participantId}}">
                          {{ prestationParticipant.personDisplay }}
                        </router-link>
                      </div>
                      <div v-else class="flex flex-1 flex-col gap-1">
                        <span class="font-inter font-semibold text-dark text-base">Partenaire {{ idx + 1 }}</span>
                        <span class="font-inter font-medium text-gray text-md">
                          {{ prestationParticipant.personDisplay }}
                        </span>
                      </div>
                      <AkCalendar
                        v-model="prestationParticipant.clockIn"
                        label="Date de validation par le partenaire"
                        disabled
                        class-name="flex-1" />
                    </div>
                    <div class="flex md:flex-row flex-col gap-2" v-if="this.isStatusPrestationCanceled">
                      <AkCalendar
                        v-model="prestationParticipant.dateCancel"
                        label="Date d'annulation"
                        disabled
                        class-name="flex-1" />
                    </div>
                    <div class="flex md:flex-row flex-col gap-2" v-if="this.isStatusPrestationCanceled">
                      <AkInputTextArea
                        auto-resize
                        :rows="4"
                        v-model="prestationParticipant.commentCancel"
                        label="Raison de l'annulation de la mission"
                        disabled
                        class-name="flex-1" />
                    </div>
                  </div>
                </div>

                <div class="flex justify-end">
                  <AkButtonValidation ref="submit" :label="$t('update')" @action="update" />
                </div>
              </AkFormSubmitted>
            </template>
          </AkLoader>
        </perfect-scrollbar>
      </div>
    </template>
  </AkSidebar>
</template>
