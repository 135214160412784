<script>
import moment from 'moment';

/* COMPOSANTS */
import AkPrestationLine from '@components/general/AkPrestationLine.vue';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import missionMixin from '@mixins/missionMixin';
import prestationTypesConst from '@mixins/const/prestationTypesConst';
import prestationStatusConst from '@mixins/const/prestationStatusConst';

/* SERVICES */

export default {
  components: {AkPrestationLine},
  mixins: [utilsMixin, permissionsMixin, missionMixin, prestationTypesConst, prestationStatusConst],
  props: {
    modelValue: {
      required: true,
    },
    dates: {
      type: Array,
      required: true,
    },
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },
  data() {
    return {
      height: '',
    };
  },
  mounted() {
    this.height = this.styleHeight();
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
    },
    title() {
      if (this.isGasGav()) return this.$t('planning_intervenant.title');
      // TODO: à décommenter pour le titre de la vue unique et supprimer la ligne du dessus
      // return (
      //   this.$t('planning_intervenant.title_of') +
      //   ` ${this.currentValue.firstname} ${this.currentValue.lastname}`
      // );
      return this.$t('menu.my_planning');
    },
    slots() {
      let list = [];
      if (this.dates)
        for (let d of this.dates) {
          let date = moment(d).startOf('date');
          let slot = {};
          slot.date = date;
          slot.firstDayOfMonth = date.date() === 1;
          slot.firstDay = d === this.dates[0];
          slot.dayNumber = date.format('DD');
          slot.dayNumberTitle = date.format('DD/MM');
          slot.dayTitle = date.format('ddd');
          slot.wk = date.isoWeekday() === 7 || date.isoWeekday() === 6;
          slot.today = date.isSame(moment().startOf('date'));
          list.push(slot);
        }
      return list;
    },
  },
  methods: {
    displayTooltip() {},
    styleHeight() {
      let p = this.$isMobile() ? 420 : 375;
      return 'max-height: calc(100vh - ' + p + 'px)';
    },
  },
};
</script>

<template>
  <perfect-scrollbar class="ak-planning">
    <div class="flex border-b border-dashed border-lightGray min-w-[1800px] lg:min-w-[auto]">
      <div class="w-full relative">
        <div class="grid w-full h-full items-center z-10 grid-cols-7">
          <div
            v-for="n in slots"
            class="text-center text-gray opacity-80 h-full flex flex-col items-center justify-center text-sm font-semibold gap-2 px-2 py-4"
            :key="n">
            <span class="uppercase font-semibold">{{ n.dayTitle }}</span>
            <span class="font-semibold">{{ n.dayNumberTitle }}</span>
          </div>
        </div>
      </div>
    </div>
    <perfect-scrollbar
      class="min-w-[1800px] lg:min-w-[auto] ps_rail_index border-l border-r border-lightGray"
      :options="{suppressScrollX: true}">
      <div v-if="currentValue" class="body" :style="this.height">
        <AkPrestationLine v-model="currentValue" :slots="slots" @tooltip="displayTooltip" />
      </div>
    </perfect-scrollbar>
    <div
      class="flex justify-between items-center gap-4 border px-2 py-2 border-lightGray min-w-[1800px] lg:min-w-[auto]">
      <div class="flex gap-4">
        <div class="flex gap-2 items-center">
          <i class="w-[12px] h-[12px] rounded-full bg-[#75BB99]"></i
          ><span class="text-dark text-base font-normal">{{ this.displayForPrestationStatusConst('TODO') }}</span>
        </div>
        <div class="flex gap-2 items-center">
          <i class="w-[12px] h-[12px] rounded-full bg-[#27B363]"></i
          ><span class="text-dark text-base font-normal">{{ this.displayForPrestationStatusConst('COMPLETED') }}</span>
        </div>
        <div class="flex gap-2 items-center">
          <i class="w-[12px] h-[12px] rounded-full bg-[#E3324B]"></i
          ><span class="text-dark text-base font-normal">{{ this.displayForPrestationStatusConst('CANCELED') }}</span>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</template>
