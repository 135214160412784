<script>
import useVuelidate from '@vuelidate/core';
import {helpers, required, requiredIf} from '@vuelidate/validators';
import moment from 'moment';

/* COMPOSANTS */
import AkEmployeeAutocomplete from '@components/general/AkEmployeeAutocomplete';
import AkHousekeeperAutocomplete from '@components/general/AkHousekeeperAutocomplete';
import AkCalendar from '@components/v2/input/AkCalendar.vue';
import AkTime from '@components/v2/input/AkTime.vue';
import AkInputTextArea from '@components/v2/input/AkInputTextArea.vue';
import employeeAutocompleteMixin from '../../../../mixins/autocomplete/employeeAutocompleteMixin';
import AkAutocomplete from '@components/v2/input/AkAutocomplete.vue';
import housekeeperAutocompleteMixin from '../../../../mixins/autocomplete/housekeeperAutocompleteMixin';

/* MIXINS */

/* SERVICES */

export default {
  components: {
    AkAutocomplete,
    AkCalendar,
    AkInputTextArea,
  },
  mixins: [employeeAutocompleteMixin, housekeeperAutocompleteMixin],
  props: {
    modelValue: {
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    dateStart: {
      type: Date,
      required: true,
    },
    duration: {
      type: Number,
      required: true,
    },
    companyId: {
      type: Number,
      required: true,
    },
    assignedStaff: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      diffInMinutes: 0,
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  validations() {
    return {
      currentValue: {
        employeeId: {required: requiredIf(this.isAssignedStaffIntern)},
        dateStart: {required},
        dateEnd: {
          required,
          isHourEndSupOrEqToHourStart: helpers.withMessage(
            this.$t('planning_intervenant.hour_end_error'),
            this.isHourEndSupOrEqToHourStart,
          ),
          duration: helpers.withMessage(this.$t('planning_intervenant.duration_error'), this.isDurationValid),
        },
        commentForEmployee: {},
        commentForHousekeeper: {},
      },
    };
  },
  watch: {
    'currentValue.dateStart'(newVal, oldVal) {
      if (newVal === oldVal || !newVal) return '';
      this.currentValue.dateEnd = this.computeDateEnd;
    },
    'currentValue.dateEnd'(newVal, oldVal) {
      this.diffInMinutes = Math.round((this.currentValue.dateEnd - this.currentValue.dateStart) / 60000);
    },
  },
  mounted() {
    this.currentValue.dateStart = this.dateStart;
    this.currentValue.dateEnd = this.computeDateEnd;
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },

    isAssignedStaffIntern() {
      return this.assignedStaff === 'INTERN';
    },

    isAssignedStaffHousekeeper() {
      return this.assignedStaff === 'HOUSEKEEPER';
    },
    computeDateEnd() {
      let start = moment(this.currentValue.dateStart);
      return start.add(this.duration, 'm').toDate();
    },
  },
  methods: {
    isHourEndSupOrEqToHourStart() {
      if (!this.currentValue.dateStart || !this.currentValue.dateEnd) return false;

      let error = moment(this.currentValue.dateStart).isBefore(moment(this.currentValue.dateEnd));
      return error;
    },
    isDurationValid() {
      return this.diffInMinutes >= this.duration;
    },
  },
};
</script>

<template>
  <div class="flex flex-col gap-2">
    <span class="title-border">{{ $t('planning_intervenant.participant') }} n°{{ index + 1 }}</span>
    <AkAutocomplete
      v-if="this.isAssignedStaffIntern"
      v-model="currentValue.employeeId"
      :init-method="autocompleteEmployeeById"
      :label="$t('planning_intervenant.participant_name')"
      :search-method="autocompleteEmployee"
      :search-object="{companyId: this.companyId, userEnabled: true}"
      :validator="v$.currentValue.employeeId"
      option-label="display"
      option-value="id"
      required />

    <AkCalendar
      v-model="currentValue.dateStart"
      :label="$t('planning_intervenant.start_date')"
      :placeholder="$t('planning_intervenant.start_date')"
      selection-mode="single"
      :validator="v$.currentValue.dateStart"
      :required="true"
      :show-time="true"
      class-name="col-6" />
    <AkCalendar
      v-model="currentValue.dateEnd"
      :label="$t('planning_intervenant.end_date')"
      :placeholder="$t('planning_intervenant.end_date')"
      selection-mode="single"
      :show-time="true"
      :validator="v$.currentValue.dateEnd"
      :required="true"
      :custom-validator="['isHourEndSupOrEqToHourStart', 'duration']"
      class-name="col-6" />
    <AkInputTextArea
      v-if="this.isAssignedStaffIntern"
      v-model="currentValue.commentForEmployee"
      :label="$t('planning_intervenant.comment')"
      :placeholder="$t('planning_intervenant.comment')"
      :validator="v$.currentValue.commentForEmployee"
      class-name="col-12" />

    <AkInputTextArea
      v-if="this.isAssignedStaffHousekeeper"
      v-model="currentValue.commentForHousekeeper"
      :label="$t('planning_intervenant.comment')"
      :placeholder="$t('planning_intervenant.comment')"
      :validator="v$.currentValue.commentForHousekeeper"
      class-name="col-12" />
  </div>
</template>
