<script>
/* COMPOSANTS */
import GaView from '@components/v2/layout/GaView.vue';
import GaListView from '@components/v2/layout/GaListView.vue';
import AkListFilter from '@components/v2/filter/AkListFilter.vue';
import AkAutocompleteFilter from '@components/v2/filter/AkAutocompleteFilter.vue';
import AkCalendarPeriodFilter from '@components/v2/filter/AkCalendarPeriodFilter.vue';
import AkAllFilter from '@components/v2/filter/AkAllFilter.vue';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import filterListMixin from '@mixins/filterListMixin';
import companyMixin from '@mixins/companyMixin';
import reservationAutocompleteMixin from '@mixins/autocomplete/reservationAutocompleteMixin';
import reservationTypesConst from '@mixins/const/reservationTypesConst';

/* SERVICES */
import missionDashboardService from '@services/missionDashboardService';

export default {
  components: {
    GaView,
    GaListView,
    AkListFilter,
    AkAutocompleteFilter,
    AkCalendarPeriodFilter,
    AkAllFilter,
    AkDatatablePagination,
  },
  mixins: [
    messageMixin,
    filterListMixin,
    companyMixin,
    reservationAutocompleteMixin,
    permissionsMixin,
    reservationTypesConst,
    sidebarMixin,
    utilsMixin,
  ],
  metaInfo() {
    return {
      title: 'mission_dashboard.title',
    };
  },
  data() {
    return {
      loadingATraiter: false,
      loadingPlanif: false,
      // LIST A TRAITER
      listATraiter: [],
      totalItemsATraiter: 0,
      genericSort: [{field: 'dateStart', order: 1}],
      filterATraiter: {
        page: 1,
        limit: 50,
      },

      // LIST PLANIFIE
      listPlanifiees: [],
      totalItemsPlanifiees: 0,
      filterPlanifiees: {
        page: 1,
        limit: 50,
      },

      filter: {
        period: [new Date(), new Date()],
      },
    };
  },
  mounted() {},
  computed: {},
  methods: {
    search() {
      this.filterATraiter['page'] = 1;
      this.filterPlanifiees['page'] = 1;
      this.searchATraiter();
      this.searchPlanif();
    },
    removeFilterItem() {
      delete this.filter.page;
      delete this.filter.limit;
    },

    searchATraiter() {
      this.loadingATraiter = true;
      this.removeFilterItem();
      this.filterATraiter = {
        ...this.filterATraiter,
        ...this.filter,
      };
      let p1 = missionDashboardService.paginationATraiter(this.filterATraiter);
      p1.then((data) => {
        this.listATraiter = data;
      })
        .catch((e) => {
          this.addError(e.response.data.error);
        })
        .finally(() => {
          this.loadingATraiter = false;
        });
    },

    searchPlanif() {
      this.loadingPlanif = true;
      this.removeFilterItem();
      this.filterPlanifiees = {
        ...this.filterPlanifiees,
        ...this.filter,
      };

      let p2 = missionDashboardService.paginationPlanifie(this.filterPlanifiees);
      p2.then((data) => {
        this.listPlanifiees = data;
      })
        .catch((e) => {
          this.addError(e.response.data.error);
        })
        .finally(() => {
          this.loadingPlanif = false;
        });
    },

    // FILTER

    openFilterPanel() {
      this.toggleFilterTitle(
        'GaMissionDashboardFilter',
        this.$t('filter_all'),
        this.filter,
        this.search,
        this.resetFilter,
      );
    },

    resetFilter() {
      this.filter = {};
      this.filterATraiter = {
        page: 1,
        limit: 50,
      };
      this.filterPlanifiees = {
        page: 1,
        limit: 50,
      };

      this.toggleFilterTitle(
        'GaMissionDashboardFilter',
        this.$t('filter_all'),
        this.filter,
        this.search,
        this.resetFilter,
      );
    },

    // SIDEBAR

    openReservationSidebar(event) {
      let data = event.data;
      data.header = data.reservationNum;
      this.toggleSidebar('GaReservationSidebar', 'Détails de la réservation', data.id, this.deleteReservationHote);
    },
  },
};
</script>

<template>
  <GaView>
    <template #content>
      <GaListView>
        <template #filter>
          <AkListFilter
            v-if="this.isGas()"
            :multi-selection="true"
            v-model="this.filter.companyIds"
            :items="this.companies"
            :display-filter="true"
            @search="this.clickSearch"
            :label="$t('company.company')"
            direction="right" />
          <AkAutocompleteFilter
            v-model="this.filter.reservationId"
            @search="this.clickSearch"
            label="Réservation"
            item-value="id"
            item-display="display"
            direction="right"
            :init-method="this.autocompleteReservationById"
            :search-method="this.autocompleteReservation" />
          <AkCalendarPeriodFilter
            v-model="this.filter.period"
            direction="right"
            @search="this.clickSearch"
            :label="$t('reservation.periodCheckin')" />
          <AkAllFilter @open="this.openFilterPanel" />
        </template>

        <template #content>
          <div class="flex overflow-hidden gap-4 md:flex-row flex-col">
            <div class="flex md:w-1/2 w-full flex-col md:h-full">
              <span class="title-border text-dark text-xl font-bold mb-3">Réservations à traiter</span>
              <AkDatatablePagination
                :value="listATraiter"
                :lazy="false"
                @search="searchATraiter"
                :loading="loadingATraiter"
                v-model:multiSortMeta="this.genericSort"
                :filter="this.filterATraiter">
                <template #empty>Aucune réservation</template>

                <Column
                  header="Réservation"
                  style="max-width: 110px; width: 110px"
                  sortable
                  sort-field="reservationNum">
                  <template #body="slotProps">
                    <router-link
                      v-if="this.isGasGav()"
                      class="break-words"
                      :to="{
                        name: 'reservationPage',
                        params: {accommodationId: slotProps.data.accommodationId, reservationId: slotProps.data.id},
                      }">
                      {{ slotProps.data.reservationNum }}
                    </router-link>
                    <span
                      v-else
                      class="text-primary hover:text-primary font-semibold cursor-pointer"
                      @click="openReservationSidebar(slotProps)">
                      {{ slotProps.data.reservationNum }}
                    </span>
                  </template>
                </Column>

                <Column
                  header="Logement"
                  style="max-width: 130px; width: 130px"
                  sortable
                  sort-field="accommodationDisplay">
                  <template #body="slotProps">
                    <router-link
                      class="break-words"
                      :to="{
                        name: 'accommodationPage',
                        params: {accommodationId: slotProps.data.accommodationId},
                      }">
                      {{ slotProps.data.accommodationDisplay }}
                    </router-link>
                  </template>
                </Column>

                <Column
                  header="Agence"
                  style="max-width: 100px; width: 100px"
                  v-if="this.isGas()"
                  sortable
                  sort-field="companyDisplay">
                  <template #body="slotProps">
                    {{ slotProps.data.companyDisplay }}
                  </template>
                </Column>
                <Column header="Séjour" style="max-width: 100px; width: 100px" sortable sort-field="dateStart">
                  <template #body="slotProps">
                    {{ displayDatesSeparator(slotProps.data.dateStart, slotProps.data.dateEnd, false) }}
                  </template>
                </Column>
                <Column header="Type" style="max-width: 80px; width: 80px" sortable sort-field="type">
                  <template #body="slotProps">
                    {{ displayForReservationTypeConst(slotProps.data.type) }}
                  </template>
                </Column>

                <Column header="Nombre" style="max-width: 70px; width: 70px" sortable sort-field="prestationNb">
                  <template #body="slotProps">
                    {{ slotProps.data.prestationNb }}
                  </template>
                </Column>
              </AkDatatablePagination>
            </div>
            <div class="flex md:w-1/2 w-full flex-col md:h-full">
              <span class="title-border text-dark text-xl font-bold mb-3">Réservations planifiées</span>
              <AkDatatablePagination
                :value="listPlanifiees"
                :lazy="false"
                v-model:multiSortMeta="this.genericSort"
                @search="searchPlanif"
                :loading="loadingPlanif"
                :filter="this.filterPlanifiees">
                <template #empty>Aucune réservation</template>

                <Column
                  header="Réservation"
                  style="max-width: 110px; width: 110px"
                  sortable
                  sort-field="reservationNum">
                  <template #body="slotProps">
                    <router-link
                      v-if="this.isGasGav()"
                      class="break-words"
                      :to="{
                        name: 'reservationPage',
                        params: {reservationId: slotProps.data.id},
                      }">
                      {{ slotProps.data.reservationNum }}
                    </router-link>
                    <span
                      v-else
                      class="text-primary hover:text-primary font-semibold cursor-pointer"
                      @click="openReservationSidebar(slotProps)">
                      {{ slotProps.data.reservationNum }}
                    </span>
                  </template>
                </Column>

                <Column
                  header="Logement"
                  style="max-width: 130px; width: 130px"
                  sortable
                  sort-field="accommodationDisplay">
                  <template #body="slotProps">
                    <router-link
                      class="break-words"
                      :to="{
                        name: 'accommodationPage',
                        params: {accommodationId: slotProps.data.accommodationId},
                      }">
                      {{ slotProps.data.accommodationDisplay }}
                    </router-link>
                  </template>
                </Column>

                <Column
                  header="Agence"
                  v-if="this.isGas()"
                  style="max-width: 100px; width: 100px"
                  sortable
                  sort-field="companyDisplay">
                  <template #body="slotProps">
                    {{ slotProps.data.companyDisplay }}
                  </template>
                </Column>

                <Column header="Séjour" style="max-width: 100px; width: 100px" sortable sort-field="dateStart">
                  <template #body="slotProps">
                    {{ displayDatesSeparator(slotProps.data.dateStart, slotProps.data.dateEnd, false) }}
                  </template>
                </Column>
                <Column header="Type" style="max-width: 80px; width: 80px" sortable sort-field="type">
                  <template #body="slotProps">
                    {{ displayForReservationTypeConst(slotProps.data.type) }}
                  </template>
                </Column>

                <Column header="Nombre" style="max-width: 70px; width: 70px" sortable sort-field="prestationNb">
                  <template #body="slotProps">
                    {{ slotProps.data.prestationNb }}
                  </template>
                </Column>
              </AkDatatablePagination>
            </div>
          </div>
        </template>
      </GaListView>
    </template>
  </GaView>
</template>
