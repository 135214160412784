<script>
import useVuelidate from '@vuelidate/core';
import {required, requiredIf} from '@vuelidate/validators';

/* COMPOSANTS */
import AkDropdown from '@components/v2/input/AkDropdown.vue';
import AkCalendar from '@components/v2/input/AkCalendar.vue';
import AkTime from '@components/v2/input/AkTime.vue';
import AkLabel from '@components/v2/general/AkLabel';
import AkPrestationOfferForm from '@views/missionordercreate/components/AkPrestationOfferForm.vue';
import AkUpsellForm from '@views/missionordercreate/components/AkUpsellForm.vue';

/* MIXINS */
import personnelTypeConst from '@mixins/const/personnelTypeConst';
import utilsMixin from '@mixins/utilsMixin';

export default {
  components: {
    AkTime,
    AkCalendar,
    AkDropdown,
    AkLabel,
    AkPrestationOfferForm,
    AkUpsellForm,
  },
  mixins: [personnelTypeConst, utilsMixin],
  props: {
    modelValue: {
      required: true,
    },
    upsells: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
  },
  emits: ['checked-upsell'],
  validations() {
    return {
      currentValue: {
        documentModelId: {required},
        assignedStaff: {required},
        date: {required},
        startHour: {required: requiredIf(this.isAssignedStaffPrestataire)},
        duration: {required},
        numberOfPresta: {required: requiredIf(this.hasNumberOfPresta)},
      },
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      prestataireNumberPossible: [
        {value: 1},
        {value: 2},
        {value: 3},
        {value: 4},
        {value: 5},
        {value: 6},
        {value: 7},
        {value: 8},
        {value: 9},
        {value: 10},
      ],
    };
  },
  mounted() {},
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },

    // UPSELLS
    isEmptyUpsells() {
      return !this.upsells || this.upsells.length === 0;
    },

    isAssignedStaffPrestataire() {
      return this.currentValue.assignedStaff === 'PRESTATAIRE';
    },
    isAssignedStaffHousekeeper() {
      return this.currentValue.assignedStaff === 'HOUSEKEEPER';
    },

    // TYPES
    isTypeCheckin() {
      return this.currentValue.type === 'CHECK_IN';
    },
    isTypeCheckout() {
      return this.currentValue.type === 'CHECK_OUT';
    },
    isTypeCleaning() {
      return this.currentValue.type === 'CLEANING';
    },
    isTypeCleaningBefore() {
      return this.currentValue.type === 'CLEANING_BEFORE';
    },
    isTypeCleaningAfter() {
      return this.currentValue.type === 'CLEANING_AFTER';
    },
    isTypeMaintenance() {
      return this.currentValue.type === 'MAINTENANCE';
    },
    isTypeRunning() {
      return this.currentValue.type === 'RUNNING';
    },
    isTypeQualityControl() {
      return this.currentValue.type === 'QUALITY_CONTROL';
    },

    // INPUT
    hasNumberOfPresta() {
      return (
        (this.isTypeCleaning ||
          this.isTypeCleaningAfter ||
          this.isTypeCleaningBefore ||
          this.isTypeMaintenance ||
          this.isTypeRunning) &&
        !this.isAssignedStaffHousekeeper
      );
    },

    personnelTypes() {
      if (!this.currentValue.isAssignedToHousekeeper) return this.personnelTypeConst.slice(0, -1);
      return this.personnelTypeConst;
    },
  },
  methods: {
    checkItem(upsell) {
      this.$emit('checked-upsell', upsell.id, upsell.missionType);
    },
    isChecked(upsell) {
      return upsell.missionType === this.currentValue.type;
    },
    getLabelForUpsell(upsell) {
      let dateCheckin = this.formatDateIfNeeded(upsell.dateCheckin);
      let dateCheckout = this.formatDateIfNeeded(upsell.dateCheckout);
      return upsell.name + ' (' + dateCheckin + ' - ' + dateCheckout + ')';
    },
    updateNumberOfPresta(nb) {
      if (nb < 1) return;
      // Redimensionner le tableau => Toujours au minimum une offre
      this.currentValue.prestationOffers.splice(nb);
      const price = this.currentValue.prestationOffers[0].priceHt;
      while (this.currentValue.prestationOffers.length < nb) {
        this.currentValue.prestationOffers.push({priceHt: price});
      }
    },
    updateAssignedStaff(event) {
      if (this.isAssignedStaffHousekeeper) {
        // Ne garder qu'une seule offre pour les concierges partenaires
        this.currentValue.numberOfPresta = 1;
      }
      this.updateNumberOfPresta(this.currentValue.numberOfPresta);
    },
  },
};
</script>

<template>
  <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
    <AkDropdown
      v-model="currentValue.documentModelId"
      :label="this.$t('mission_order.document_model')"
      :placeholder="this.$t('mission_order.document_model')"
      :options="currentValue.documentModelList"
      option-label="name"
      option-value="id"
      :validator="v$.currentValue.documentModelId" />
  </div>

  <div class="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
    <AkDropdown
      v-model="currentValue.assignedStaff"
      :label="this.$t('mission_order.assigned_staff')"
      :placeholder="this.$t('mission_order.assigned_staff')"
      :options="personnelTypes"
      option-label="label"
      option-value="value"
      :validator="v$.currentValue.assignedStaff"
      @value-change="updateAssignedStaff($event)" />
    <AkCalendar
      v-model="currentValue.date"
      :label="this.$t('mission_order.date')"
      :placeholder="this.$t('mission_order.date')"
      :validator="v$.currentValue.date"
      date-format="dd/mm/yy"
      :min-date="new Date()" />
    <AkTime
      v-model="currentValue.duration"
      :label="this.$t('mission_order.duration')"
      :placeholder="this.$t('mission_order.duration')"
      :validator="v$.currentValue.duration" />

    <!-- Spécifique prestataires -->
    <AkTime
      v-if="this.isAssignedStaffPrestataire"
      v-model="currentValue.startHour"
      :label="this.$t('mission_order.start_hour')"
      :placeholder="this.$t('mission_order.start_hour')"
      :validator="v$.currentValue.startHour" />
  </div>

  <div v-if="!this.isEmptyUpsells" class="mb-4">
    <AkLabel>Extras</AkLabel>
    <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
      <AkUpsellForm
        v-for="upsell in this.upsells"
        :key="upsell.id"
        v-model="upsell.missionType"
        :label="getLabelForUpsell(upsell)"
        :missionType="currentValue.type"
        :checked="isChecked(upsell)"
        @checked-upsell="checkItem(upsell)" />
    </div>
  </div>

  <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
    <AkDropdown
      v-model="currentValue.numberOfPresta"
      :label="this.$t('mission_order.number_staff')"
      :placeholder="this.$t('mission_order.number_staff')"
      :options="prestataireNumberPossible"
      option-label="value"
      option-value="value"
      :validator="v$.currentValue.numberOfPresta"
      :disabled="!this.hasNumberOfPresta"
      @value-change="updateNumberOfPresta($event)" />

    <div class="grid grid-cols-subgrid md:col-span-2 gap-4">
      <AkPrestationOfferForm
        v-for="(item, idx) in currentValue.prestationOffers"
        :key="item.id"
        v-model="currentValue.prestationOffers[idx]"
        :index="idx"
        :showPriceInput="this.isAssignedStaffPrestataire" />
    </div>
  </div>
</template>
