<script>
/* COMPOSANTS */
import AkAddress from '@components/v2/general/AkAddress';
import AkButtonAction from '@components/v2/general/AkButtonAction';
import AkButtonActionValidation from '@components/v2/general/AkButtonActionValidation';

/* MIXINS */
import roleKeysConst from '@mixins/const/roleKeysConst';
import civilitiesConst from '@mixins/const/civilitiesConst';
import loaderMixin from '@mixins/loaderMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import accommodationContactService from '@services/accommodationContactService';

export default {
  components: {
    AkAddress,
    AkButtonAction,
    AkButtonActionValidation,
  },
  mixins: [civilitiesConst, loaderMixin, permissionsMixin, roleKeysConst, sidebarMixin, utilsMixin],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      contactTypes: undefined,
    };
  },
  mounted() {},
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },

    // CONTACT KIND

    kind() {
      if (!this.currentValue) return null;
      return this.currentValue.kind;
    },
    isInternal() {
      if (!this.kind) return false;
      return this.kind === 'INTERNAL';
    },
    isExternal() {
      if (!this.kind) return false;
      return this.kind === 'EXTERNAL';
    },

    // POPUP

    titlePopup() {
      if (!this.currentValue) return '';
      return this.$t(this.isInternal ? 'contact.internal_confirm_title' : 'contact.external_confirm_title');
    },
    messageDeletePopup() {
      if (!this.currentValue) return '';
      return this.$t(this.isInternal ? 'contact.internal_confirm_delete' : 'contact.external_confirm_delete');
    },

    // FORM

    technicalName() {
      if (!this.currentValue) return null;
      if (!this.currentValue.technicalName) return null;
      return this.displayForRoleKeysConst(this.currentValue.technicalName);
    },
    civility() {
      if (!this.currentValue) return null;
      return this.displayForCivilityConst(this.currentValue.civility) ?? '---';
    },

    // CAN

    canDelete() {
      // seuls les GAS ont le droit de supprimer un contact
      if (!this.isGas()) return false;

      // les contacts externes peuvent être supprimés
      if (this.isExternal) return true;

      // seuls les contacts internes de type employé peuvent être supprimé (désassociation)
      return !!this.currentValue.employeeId;
    },
    canModify() {
      // seuls les GAS ont le droit de modifier un contact
      if (!this.isGas()) return false;

      // seuls les contacts externes peuvent être modifié
      return this.isExternal;
    },
  },
  methods: {
    delete() {
      this.callback[0]();
      this.hideSidebar();
    },
    update() {
      this.$router.push({
        name: 'accommodationContactUpdate',
        params: {accommodationId: this.$route.params.accommodationId, contactId: this.currentValue.id},
      });
      this.hideSidebar();
    },
  },
};
</script>

<template>
  <div v-if="currentValue" class="flex flex-col md:gap-4 gap-2">
    <div class="grid grid-cols-2 md:gap-4 gap-2">
      <div class="flex flex-col gap-1">
        <span class="font-inter font-semibold text-dark text-base">{{ $t('accommodationContact.type') }}</span>
        <span class="font-inter font-medium text-gray text-md">{{ currentValue.type2Display }}</span>
      </div>

      <div class="flex flex-col gap-1" v-if="this.technicalName">
        <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.role') }}</span>
        <span class="font-inter font-medium text-gray text-md">{{ this.technicalName }}</span>
      </div>

      <div class="flex flex-col gap-1">
        <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.civility') }}</span>
        <span class="font-inter font-medium text-gray text-md">{{ this.civility }}</span>
      </div>

      <div class="flex flex-col gap-1">
        <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.lastName') }}</span>
        <span class="font-inter font-medium text-gray text-md">{{ currentValue.lastName ?? '---' }}</span>
      </div>

      <div class="flex flex-col gap-1">
        <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.firstName') }}</span>
        <span class="font-inter font-medium text-gray text-md">{{ currentValue.firstName ?? '---' }}</span>
      </div>
    </div>

    <!-- MAP -->
    <AkAddress v-model="this.currentValue" />

    <div class="grid grid-cols-2 md:gap-4 gap-2">
      <div class="flex flex-col gap-1">
        <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.email') }}</span>
        <span class="font-inter font-medium text-gray text-md">{{ currentValue.email ?? '---' }}</span>
      </div>

      <div class="flex flex-col gap-1">
        <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.phone') }}</span>
        <span class="font-inter font-medium text-gray text-md">{{ currentValue.phone ?? '---' }}</span>
      </div>

      <div class="flex flex-col gap-1">
        <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.phone2') }}</span>
        <span class="font-inter font-medium text-gray text-md">{{ currentValue.phone2 ?? '---' }}</span>
      </div>
    </div>

    <div class="grid grid-cols-2 md:gap-4 gap-2 col-span-2">
      <AkButtonActionValidation
        v-if="this.canDelete"
        ref="btnDelete"
        :label="$t('delete')"
        :titlePopup="this.titlePopup"
        :messagePopup="this.messageDeletePopup"
        danger
        @click="this.delete" />

      <AkButtonAction v-if="this.canModify" ref="btnUpdate" :label="$t('update')" @click="this.update" />
    </div>
  </div>
</template>
