<script>
/* COMPOSANTS */
import AkAvatarStack from '@components/general/AkAvatarStack.vue';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import missionMixin from '@mixins/missionMixin';

/* SERVICES */

export default {
  props: {
    mission: {
      required: true,
    },
  },
  components: {AkAvatarStack},
  mixins: [utilsMixin, missionMixin],
  computed: {
    cssForType() {
      if (this.mission.prestation.type) {
        return this.mission.prestation.type.toLowerCase().replace('_', '-');
      } else {
        return '';
      }
    },
    displayParticipantName() {
      let names = [];
      for (let p of this.mission.participants) {
        names.push(p.display);
      }
      return names.join(', ');
    },
    displayParticipantNb() {
      if (this.isAssignedStaffIntern(this.mission.prestation)) return this.displayInternNb;
      if (this.isAssignedStaffHousekeeper(this.mission.prestation)) return this.displayHousekeeperNb;
      return this.displayPrestataireNb;
    },
    displayInternNb() {
      if (this.mission.participants.length === 0) return 'Aucun salarié';
      if (this.mission.participants.length === 1) return '1 salarié';
      return this.mission.participants.length + ' salariés';
    },
    displayPrestataireNb() {
      if (this.mission.participants.length === 0) return 'Aucun prestataire';
      if (this.mission.participants.length === 1) return '1 prestataire';
      return this.mission.participants.length + ' prestataires';
    },
    displayHousekeeperNb() {
      if (this.mission.participants.length === 0) return 'Aucun concierge';
      if (this.mission.participants.length === 1) return '1 concierge';
      return this.mission.participants.length + ' concierges';
    },
    isTypeCleaning() {
      return (
        this.mission.prestation.type === 'CLEANING' ||
        this.mission.prestation.type === 'CLEANING_AFTER' ||
        this.mission.prestation.type === 'CLEANING_BEFORE'
      );
    },
    isTypeCheckin() {
      return this.mission.prestation.type === 'CHECK_IN';
    },
    isTypeCheckout() {
      return this.mission.prestation.type === 'CHECK_OUT';
    },
    isTypeMaintenanceOrRunning() {
      return this.mission.prestation.type === 'RUNNING' || this.mission.prestation.type === 'MAINTENANCE';
    },
    isTypeQualityControl() {
      return this.mission.prestation.type === 'QUALITY_CONTROL';
    },
  },
  mounted() {},
  methods: {},
};
</script>

<template>
  <component
    :is="this.hasAccessToMission ? 'router-link' : 'div'"
    :to="{name: 'mission-order-detail', params: {missionOrderId: this.mission.prestation.id}}">
    <div
      :class="`--${this.cssForType}`"
      class="border rounded-lg flex items-center gap-4 prestation-border bg-white overflow-hidden shrink-0">
      <div :class="`--${this.cssForType}`" class="prestation-bg flex justify-center p-4 self-stretch">
        <div class="badge-icon self-center">
          <i class="ga-icon ga-aspirateur text-2xl text-white" v-if="this.isTypeCleaning" />
          <i class="ga-icon ga-user_delete text-2xl text-white" v-if="this.isTypeCheckout" />
          <i class="ga-icon ga-user_edit text-2xl text-white" v-if="this.isTypeCheckin" />
          <i class="ga-icon ga-user_out text-2xl text-white" v-if="this.isTypeMaintenanceOrRunning" />
          <i class="ga-icon ga-user_checked text-2xl text-white" v-if="this.isTypeQualityControl" />
        </div>
      </div>
      <div class="flex flex-col flex-1 py-2">
        <div class="text-md font-semibold text-primary">
          {{ this.mission.prestation.accommodationName }}
        </div>
        <div class="text-base font-medium text-gray">
          {{ displayDateTime(mission.prestation.dateStart) }} - {{ displayDateTime(mission.prestation.dateEnd) }}
        </div>
        <div class="text-base text-gray" v-if="this.hasAccessToMission">
          {{ displayParticipantNb }} - {{ displayParticipantName }}
        </div>
      </div>
      <div class="flex pr-2 justify-end self-center">
        <AkAvatarStack :avatars="this.mission.participants" :limit="3" size="medium" v-if="this.mission.participants" />
      </div>
    </div>
  </component>
</template>
